.App {
  text-align: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  padding: 0;
  transition: background-color 0.3s ease;
}

/* Light theme variables (default) */
:root {
  --bg-color: #f5f5f5;
  --text-color: #333;
  --card-bg: white;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --price-color: #2c5282;
}

/* Dark theme variables */
[data-theme='dark'] {
  --bg-color: #121212;
  --text-color: #ffffff;
  --card-bg: #1e1e1e;
  --card-shadow: rgba(0, 0, 0, 0.3);
  --price-color: #60a5fa;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--text-color);
  margin-bottom: 40px;
}

.price-cards {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.price-card {
  background: var(--card-bg);
  border-radius: 10px;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0 4px 6px var(--card-shadow);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

h2 {
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: var(--price-color);
  transition: color 0.3s ease;
}

.error {
  color: #e53e3e;
  padding: 20px;
  background: #fff5f5;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 500px;
}

.logo {
  font-size: 32px;
  font-weight: bold;
}
